import './loading.css'
let modal, loadingImg, wrapper

export default function Loading (config) {
  this.src = config.src || 'ecar/images/loading.gif'
  this._init()
}

Loading.prototype = {
  _init: function () {
    this._create()
    this.show()
  },
  _create: function () {
    if (!modal) {
      wrapper = document.createElement('div')
      wrapper.className = 'fixed_top_extra dis_flex_left_extra state_img_extra extra_bg_tm_00'
      wrapper.style.height = '100%'
      wrapper.style.width = '100%'
      wrapper.style.zIndex = '100010'
      wrapper.style.fontSize = '0'

      modal = document.createElement('div')
      modal.className = 'state_img_extra bg_ff bor_rad_extra'
      modal.style.padding = '18px'
      modal.style.width = '120px'

      loadingImg = document.createElement('img')
      loadingImg.style.width = '90px'
      loadingImg.style.height = '47px'
      loadingImg.setAttribute('src', this.src)

      modal.appendChild(loadingImg)

      wrapper.appendChild(modal)
      document.body.appendChild(wrapper)
    }
  },
  show: function () {
    wrapper.style.display = 'flex'
  },
  hide: function () {
    wrapper.style.display = 'none'
  }
}
