/**
 * Created by wqj on 2017/7/28.
 */
import extra from './index'
import picker from '../picker/src/index'

if (window.weex) {
  weex.install(extra)
  weex.install(picker)
}
