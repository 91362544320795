/**
 * Created by wqj on 2017/7/28.
 */

import Loading from './Loading'
import Alert from './Alert'

const tools = {
  loadingView: null
}

const extraModule = {
  loading: function (options) {
    if (!tools.loadingView) {
      options = options || {}
      tools.loadingView = new Loading(options)
    }
  },
  rmloading: function () {
    if (tools.loadingView) {
      tools.loadingView.hide()
      tools.loadingView = null
    }
  },
  alert: function (options, callback) {
    setTimeout(function () {
      new Alert(options, callback)
    }, 100)
  }
}
const meta = {
  extra: [
    {
      name: 'loading',
      args: ['object', 'function']
    },
    {
      name: 'rmloading',
      args: ['object', 'function']
    },
    {
      name: 'alert',
      args: ['object', 'function']
    }
  ]
}
export default {
  init: function (Weex) {
    Weex.registerApiModule('JCModal', extraModule, meta)
  }
}
