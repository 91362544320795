import Picker from './Picker'
import moment from 'moment'

let maxDate = null
let minDate = null
let inputData = null

const createData = function () {
  const year = []
  const month = []
  // const days = []

  let i = 0
  for (i = parseInt(minDate.format('YYYY')); i <= parseInt(maxDate.format('YYYY')); i++) {
    // if (i < 10) {
    year.push(i + '')
    // } else {
    //   hour.push(i + '')
    // }
  }
  for (i = 1; i <= 12; i++) {
    if (i < 10) {
      month.push('0' + i)
    }
    else {
      month.push(i + '')
    }
  }

  // for (i = 0; i < 60; i++) {
  //   if (i < 10) {
  //     days.push('0'+ i)
  //   } else {
  //     days.push(i + '')
  //   }
  // }

  return [year, month]
}

const getDefaultIndexs = function (time) {
  const year = time.format('YYYY')
  const month = time.format('MM')
  // const day = time.format('DD')
  const result = [0, 0]
  let i = 0
  for (i = 0; i < inputData[0].length; i++) {
    if (year === inputData[0][i]) {
      console.log('year: ', year)
      result[0] = i
    }
  }
  for (i = 0; i < inputData[1].length; i++) {
    if (month === inputData[1][i]) {
      console.log('month: ', month)
      result[1] = i
    }
  }
  // for (i = 0; i < inputData[2].length; i++) {
  //   if (day === inputData[2][i]) {
  //     console.log('day: ', day)
  //     result[2] = i
  //   }
  // }

  return result
}

const formateResult = function (data, indexs) {
  const year = data[0][indexs[0]]
  const month = data[1][indexs[1]]

  return year + '-' + month
}

export default function (configs) {
  const time = moment(configs.value || new Date(), 'YYYY-MM-DD HH:mm')
  maxDate = moment(configs.max || '1990-01-01', 'YYYY-MM-DD HH:mm')
  minDate = moment(configs.min || new Date(), 'YYYY-MM-DD HH:mm')
  inputData = createData()
  const value = getDefaultIndexs(time)

  configs.defaultIndexs = value
  configs.formateResult = formateResult
  configs.data = inputData

  return new Picker(configs)
}
