/**
 * Created by wqj on 2017/9/8.
 */
import './loading.css'

let alertBg, alertContent, okBtn, cancelBtn

export default function Alert (options, callback) {
  this.title = options.title
  this.titleColor = options.titleColor
  this.titleBgColor = options.titleBgColor
  this.attribute = options.attribute
  this.message = options.message
  this.okTitle = options.okTitle
  this.cancelTitle = options.cancelTitle
  this.left = options.left || 'no'
  this.callback = callback
  this._init()
}

Alert.prototype = {

  _init: function () {
    this._create()
    this._addEvent()
  },
  _create: function () {
    if (!alertBg) {
      alertBg = createBg()

      alertContent = createContainer(this.left === 'yes' ? 'extra_left_align' : 'state_img_extra')
      document.body.appendChild(alertBg)
      alertBg.appendChild(alertContent)
    }
    const msg = document.createElement('div')
    msg.className = this.titleBgColor ? '' : 'extra_bor_bot'

    const msgWrapper = document.createElement('div')
    if (this.title) {
      const tlp = this.titleBgColor ? createTlCt(this.title) : createTitle(this.title)
      msgWrapper.appendChild(tlp)
    }
    if (this.message) {
      const tlp = this.titleBgColor ? createAttrMsg(this.message, this.attribute) : createMessage(this.message)
      tlp.className = this.left === 'yes' ? 'extra_left_align' : 'extra_align_cont'
      // tlp.innerText = this.message
      msgWrapper.appendChild(tlp)
    }
    msg.appendChild(msgWrapper)
    alertContent.appendChild(msg)

    const btnWrapper = document.createElement('div')
    btnWrapper.className = 'dis_flex_left_extra'

    if (this.okTitle && this.cancelTitle) {
      okBtn = createBtn(this.okTitle, '', 'extra_ft_cor_FC7944')
      cancelBtn = createBtn(this.cancelTitle, 'extra_bor_right', 'extra_ft_cor_33')

      btnWrapper.appendChild(cancelBtn)
      btnWrapper.appendChild(okBtn)
    }
    else if (this.titleBgColor) {
      cancelBtn = document.createElement('div')
      cancelBtn.className = 'extra_radius extra_bor_bot extra_bg_fc7944 extra_align_cont'
      cancelBtn.style.height = '30px'
      cancelBtn.style.lineHeight = '30px'
      cancelBtn.style.width = '60px'
      cancelBtn.style.fontSize = '15px'
      cancelBtn.style.margin = '10px'
      cancelBtn.style.backgroundColor = '#ffffff'
      cancelBtn.style.color = this.titleColor ? this.titleColor : '#fff'
      cancelBtn.innerText = this.cancelTitle || this.okTitle

      btnWrapper.className = btnWrapper.className + ' extra_fix_layer'

      btnWrapper.appendChild(cancelBtn)
    }
    else {
      cancelBtn = createBtn(this.cancelTitle || this.okTitle, '', 'extra_ft_cor_FC7944')
      btnWrapper.appendChild(cancelBtn)
    }
    alertContent.appendChild(btnWrapper)
  },
  _addEvent: function () {
    const mv = this
    okBtn && okBtn.addEventListener('click', function (e) {
      e.preventDefault()
      e.stopPropagation()
      mv.hide()
      mv.callback && mv.callback({ result: 'ok' })
    }, true)
    cancelBtn && cancelBtn.addEventListener('click', function (e) {
      e.preventDefault()
      e.stopPropagation()
      mv.hide()
      const ret = mv.okTitle && mv.cancelTitle ? 'cancel' : 'ok'
      mv.callback && mv.callback({ result: ret })
    }, true)
  },
  hide: function () {
    document.body.removeChild(alertBg)
    alertBg.innerHTML = ''
    alertContent.innerHTML = ''
    alertBg = null
    alertContent = null
    okBtn = null
    cancelBtn = null
  }
}

function createBg () {
  const bgWrapper = document.createElement('div')
  bgWrapper.className = 'fixed_top_extra dis_flex_left_extra state_img_extra extra_bg_tm_00'
  bgWrapper.style.height = '100%'
  bgWrapper.style.width = '100%'
  bgWrapper.style.zIndex = '100010'

  return bgWrapper
}
function createContainer (extraCls) {
  const container = document.createElement('div')
  container.className = 'bor_rad_extra ' + extraCls
  container.style.width = '80%'
  container.style.backgroundColor = '#ffffff'

  return container
}
function createTitle (title) {
  const tlp = document.createElement('p')
  tlp.className = 'extra_align_cont'
  tlp.style.paddingLeft = '10px'
  tlp.style.paddingRight = '10px'
  tlp.style.fontSize = '15px'
  tlp.innerText = title

  return tlp
}
function createTlCt (title) {
  const tlCt = document.createElement('div')
  tlCt.className = 'extra_bg_fc7944 dis_flex_left_extra extra_top_cor state_img_extra'
  tlCt.style.height = '35px'
  const tlp = createTitle(title)
  tlp.style.color = '#fff'
  tlCt.appendChild(tlp)

  return tlCt
}
function createMessage (title) {
  const tlp = document.createElement('p')
  tlp.style.paddingLeft = '20px'
  tlp.style.paddingRight = '20px'
  tlp.style.paddingTop = '10px'
  tlp.style.paddingBottom = '10px'
  tlp.style.fontSize = '14px'
  tlp.style.lineHeight = '20px'
  tlp.innerText = title

  return tlp
}
function createAttrMsg (message, attr) {
  const str = attr.string
  const attrCl = attr.color

  message = message.replace(str, '<span style=\'color: ' + attrCl + '\'>' + str + '</span>')
  const msgp = createMessage()
  msgp.innerHTML = message

  return msgp
}

function createBtn (title, extraCls, textCls) {
  const btn = document.createElement('div')
  btn.className = 'extra_layer_flex ' + extraCls
  btn.style.height = '50px'

  const btnText = document.createElement('p')
  btnText.className = 'extra_align_cont ' + textCls
  btnText.style.fontSize = '15px'
  btnText.innerText = title

  btn.appendChild(btnText)

  return btn
}

